import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"
import removeDuplicates from '../utils/removeDuplicates'
import { DropdownMenu, DropdownButton, MenuList, MenuItem, DefaultItem, DropdownGroup } from "./Dropdowns"

export default function YearFilters(props) {
    const data = useStaticQuery(graphql`
    query YearFilterQuery {
        allWpRelease(filter: {status: {eq: "publish"}}) {
            nodes {
                title
                id
                date(formatString: "Y")
                ltaRecordsSettings {
                    recordArtistSelection {
                      ... on WpArtist {
                        id
                        title
                        slug
                      }
                    }
                  }
            }
        }
        allWpArtist(
            filter: {status: {eq: "publish"}, bioFeaturedVideos: {isLtaRecordsArtist: {eq: true}}}
          ) {
            nodes {
                title 
                id
                slug
            }
        }
    }
  `)

    const { buttonText, setButtonText, dropZone, ...rest } = props;

    const yearData = data.allWpRelease.nodes

    let sanitizedData = removeDuplicates(yearData.sort((a,b) =>  new Date(b.date) - new Date(a.date)), "year");

    return (
        <DropdownGroup {...rest}>
                <DropdownMenu>
                <DropdownButton buttonText={buttonText} initialText={`Any Year`} setButtonText={setButtonText} />
                    <MenuList filterGroupName={`year-group`} dropZone={dropZone === "left" ? 'left' : 'right'}>
                        <DefaultItem buttonText={buttonText} initialText={`Any Year`} 
                            onClick={() => {
                                setButtonText(`Any Year`);
                                navigate(`/records`)
                            }}
                        />
                            {
                                sanitizedData.map((year, index) => {
    
                                    let filterName = year
    
                                    return (
                                        <MenuItem 
                                        key={index}
                                        buttonText={buttonText}
                                        filterName={filterName}
                                        filterSlug={filterName}
                                        onClick={() => {
                                            setButtonText(filterName);
                                            navigate(`/records/${filterName}`)
                                            }}
                                        />
                                    )
                                })
                            }
    
                    </MenuList>
                    
    
                </DropdownMenu>
            </DropdownGroup>
    )
}