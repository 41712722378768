import React, { Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { HiOutlineChevronDown, HiOutlineCheck } from 'react-icons/hi'
import { dropdownMenu, dropdownButton, menuItems, dropLeft, dropRight, dropdownItem, allItem, dropdownActive, dropdownSelected, icon } from "../styles/dropdown.module.scss"


export const DropdownMenu = props => {

    return (
        <div>
            <Menu as="div" className={dropdownMenu}>
                { props.children }
            </Menu>

      </div>
    )
}

DropdownMenu.defaultProps = {
    filterGroupName: `filter-group`
}

export const DropdownButton = props => {

    const { buttonText, setButtonText, initialText } = props;

    useEffect(function () {    
        setButtonText(initialText);
      }, [initialText, setButtonText]);

    return (

        <div>
          <Menu.Button className={dropdownButton}
          >
            { buttonText }
            <HiOutlineChevronDown size={14} className={`ml-2`} />
          </Menu.Button>
        </div>
    )
}

export const MenuList = props => {

    const { dropZone } = props;

    // if (dropZone === "left") {
    //     return dropLeft
    // }

   let menuListClassName = [menuItems, dropRight].join(" ");
   
   if (dropZone === "left") menuListClassName = [menuItems, dropLeft].join(" ");

    return (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={menuListClassName}>
            <div className="px-1 py-1 button-group" data-filter-group={props.filterGroupName}>
                { props.children }
            </div>

          </Menu.Items>

        </Transition>
    )
}

MenuList.defaultProps = {
    filterGroupName: `filter-group`,
    dropZone: `right`
}

export const MenuItem = props => {

    return (
        <Menu.Item key={props.key}>
            {({ active }) => (
            <button
                className={`${
                active  ? dropdownActive : ''
                } ${props.filterSlug} ${dropdownItem} ${props.buttonText === props.filterName ? dropdownSelected : ''}  group filterBtn`}
                    
                data-filter={props.filterSlug}
                onClick={() => {props.onClick()}}
            >

                <HiOutlineCheck
                    size={18}
                    className={icon}
                />
                { props.filterName }
            </button>
            )}
         </Menu.Item>
    )
}

export const DefaultItem = props => {

    return (
        <Menu.Item>
                    <button
                        onClick={() => {props.onClick()}}
                        
                        data-filter={``}
                        className={`${[dropdownItem, allItem].join(" ")} ${props.buttonText === props.initialText ? dropdownSelected : ''}`}
                    >
                        <HiOutlineCheck size={18} className={icon} />
                        {props.initialText}
                    </button>
        </Menu.Item>
    )
}

export const DropdownGroup = ( { className, children } ) => {

    let filterGroupClassName = `flex flex-row justify-start items-center space-x-5`;

    if (className) filterGroupClassName = [filterGroupClassName, className].join(" ");

    return (

        <div className={filterGroupClassName}>
            { children }
        </div>
    )
}


