export default function removeDuplicates(arr, version) {
    var theData = arr.map(item=>{
        if (version === "artist") return [JSON.stringify(item.ltaRecordsSettings.recordArtistSelection),item.ltaRecordsSettings.recordArtistSelection]

        else if (version === "year") return [JSON.stringify(item.date),item.date]
        
        return [JSON.stringify(item),item]
    }); // creates array of array
    var mappedArray = new Map(theData); // create key value pair from array of array
    
    var result = [...mappedArray.values()];

    return result
}