import React from 'react'
import { Link } from 'gatsby'
import Grid, { GridItem } from './Grid'
import MetaInfo from '../components/MetaInfo'
import CoverMedia from '../components/CoverMedia'
import { useMediaQuery } from 'react-responsive'


export default function RecordsFeed(props) {

    const { recordData } = props;

    let recordGridClassName = `grid-cols-2 md:grid-cols-3 gap-3 gap-y-3`;
    if (props.gridClassName) recordGridClassName = props.gridClassName;

    const aboveMd = useMediaQuery({ query: '(min-width: 768px)' });

    if (!recordData) return null

    return (
        <Grid className={recordGridClassName}>
                    {
                        recordData.map(record => {

                            const getArtistClassName = [
                                record.node.ltaRecordsSettings.recordArtistSelection !== null ? (
                                    record.node.ltaRecordsSettings.recordArtistSelection.slug
                            ) : (
                                    record.node.slug
                            )
                            ]
        
                            const artistClassName = getArtistClassName;
        
                            const getReleaseYear = new Date(record.node.date);
                            const releaseDateClassName = getReleaseYear.getFullYear();
                            const releaseDateYear = getReleaseYear.getFullYear();
        
                            const releaseItemClassName = [artistClassName, releaseDateClassName].join(" ");
        
                            const determineReleaseName = (r) => {
                           
        
                                if (r.includes(record.node.ltaRecordsSettings.recordArtistSelection.title)) {
                                    return r.replace(" – LP", "").replace(" - LP", "");
                                }
            
                                return r
                            }
        
                            const recordTitle = determineReleaseName(record.node.title);

                            return (

                                <GridItem className={`${releaseItemClassName} relative`} key={record.node.id}>

                                    {record.node.featuredImage &&
                                        <CoverMedia itemData={record.node}>

                                            <MetaInfo metaData={record.node} titleEdit={ recordTitle } />
                                            
                                        </CoverMedia>
                                    }

                                    {
                                        !aboveMd && <Link className={`absolute top-0 right-0 left-0 bottom-0 h-full w-full block z-5`} to={`/records/${releaseDateYear}/${record.node.slug}`}>&nbsp;</Link>

                                    }

                                </GridItem>
                            )
                        })
                    }
                    
                </Grid>
    )
}