import React from 'react'
import { Link } from 'gatsby';
import { HiOutlineArrowRight, HiOutlineArrowLeft } from 'react-icons/hi';

export default function Pagination(props) {

    const { context } = props;

    if (!context.nextPagePath && !context.previousPagePath) return null

    return (
        <div>

                {(context.nextPagePath || context.previousPagePath) &&

                <div className={`flex flex-row items-center justify-between text-color-text text-xs uppercase font-mono`}>
                    <div className={`text-left`}>
                    {context.previousPagePath &&
                        <Link className={`inline-flex flex-row justify-start items-center`} to={context.previousPagePath}><HiOutlineArrowLeft className={`mr-2 md:-mt-0.5 md:mr-3`} /> Earlier</Link>
                    }
                    </div>
                    <div className={`text-right`}>
                    {context.nextPagePath &&
                        <Link className={`inline-flex flex-row justify-end items-center`} to={context.nextPagePath}>Later <HiOutlineArrowRight className={`ml-2 md:-mt-0.5 md:ml-3`} /></Link>
                    }
                    </div>
                    
                    
                
                </div>
                }

                
        </div>

    )
}
