import React from 'react'

export const GridItem = props => {

    const { children, ...rest } = props;


    return (

        <div {...rest}>
            { children }
        </div>
    )
}

export default function Grid(props) {
    const {className, children, ...rest } = props;
    let gridClassName = 'grid-cols-2 md:grid-cols-3 gap-3 gap-y-3';

    if (className) gridClassName = className

    return (
        <div {...rest}>
            <div className={`grid ${gridClassName}`}>

                { children }

            </div>

        </div>
    )
}