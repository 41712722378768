import React from 'react'
import isTodayOrBefore from '../utils/isTodayOrBefore';
import Button from './Button';
import { metaSmall } from "../styles/media.module.scss"
import sanitizeReleaseName from '../utils/sanitizeRecordTitle';
import { useMediaQuery } from 'react-responsive'

export default function MetaInfo(props) {

    const { metaData, smallMode, titleEdit } = props

    const aboveMd = useMediaQuery({ query: '(min-width: 768px)' });

    if (!metaData) return null;

    const recordArtistName = metaData.ltaRecordsSettings.recordArtistSelection.title;

    let recordTitle = sanitizeReleaseName(metaData.title);


    if (titleEdit) recordTitle = titleEdit;


    const getRecordReleaseYear = new Date(metaData.date);
    const getRecordReleaseDate = new Date(metaData.ltaRecordsSettings.releaseDate) || getRecordReleaseYear;

    const { recordPreorderLink, recordPurchaseLink, recordStreamLink, lpNumber } = metaData.ltaRecordsSettings;

    const streamBtnAttr = `Stream ${recordTitle} by ${recordArtistName}`;
    const buyBtnAttr = `Purchase ${recordArtistName}'s ${recordTitle}`;
    const preBtnAttr = `Preorder ${recordArtistName}'s ${recordTitle}`;

    if (!aboveMd) return (
        <div className={smallMode === true ? 'p-5 relative z-10' : 'block'}>
            <h3 className={`uppercase text-sm font-medium mb-0`}>{recordTitle}</h3>
            {recordArtistName &&
                    <span className={`block font-extrabold uppercase text-sm font-mono`}>{recordArtistName}</span>
            }
        </div>
    )

    return (
        <div className={`text-center md:text-left md:p-5 p-2 ${smallMode === true ?  metaSmall : ''}`}>
            {metaData.title &&
                <div className={`space-y-3`}>
                    <h3 className={`italic text-xs uppercase font-medium font-mono mb-0`}>{recordTitle}</h3>
                    {recordArtistName &&
                        <span className={`block !mt-0 font-extrabold uppercase text-xs font-mono`}>{recordArtistName}</span>
                    }

                    {
                        getRecordReleaseDate &&

                        <>
                            

                                {
                                    isTodayOrBefore(getRecordReleaseDate) === true ? (<>

                                    <div className={`space-x-2 md:space-x-3`}>
                                    
                                    
                                    { recordPurchaseLink && 
                                        <Button style={{padding: '0.35rem 0.75rem', fontSize: '75%'}}  type={`external`} href={recordPurchaseLink} title={buyBtnAttr}>Buy</Button>
                                     }
                                     { recordStreamLink && 
                                        <Button style={{padding: '0.35rem 0.75rem', fontSize: '75%'}}  variation={`secondary`} type={`external`} href={recordStreamLink} title={streamBtnAttr}>Stream</Button>
                                     }

                                     </div>

                                    <div className={`uppercase tracking-wide text-xxs font-mono leading-relaxed`}>
                                     <time>{metaData.ltaRecordsSettings.releaseDate}</time>
                                     {metaData.imprints.nodes.length > 0 &&

                                        <div>
                                            <span>Imprint: </span>
                                            {metaData.imprints.nodes.map((imp, index) => {
                                                return <span key={index}>{ (index ? ', ' : '' ) + imp.name }</span>
                                            })}
                                        </div>
                                     }

                                     {lpNumber &&
                                        <span className={`block`}>{lpNumber}</span>
                                     }
                                    </div>

                                   
                                    
                                    </>) : (<>
                                    
                                        {recordPreorderLink &&
                                            <div>
                                                <Button style={{padding: '0.35rem 0.75rem', fontSize: '75%'}} type={`external`} href={recordPreorderLink} title={preBtnAttr}>Preorder</Button>
                                            </div>
                                        }

                                    <div className={`uppercase tracking-wide text-xxs font-mono leading-relaxed`}>
                                        Out: <time>{metaData.ltaRecordsSettings.releaseDate}</time>

                                        {metaData.imprints.nodes.length > 0 &&

                                        <div>
                                            <span>Imprint: </span>
                                            {metaData.imprints.nodes.map((imp, index) => {
                                                return <span key={index}>{ (index ? ', ' : '' ) + imp.name }</span>
                                            })}
                                        </div>
                                        }
                                    </div>

                                        
                                    </>

                                    )

                                    

                                    
                                }



                        </>
                    }
                </div>
            }
        
        </div>
    )
}

MetaInfo.defaultProps = {
    smallMode: false
}